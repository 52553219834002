<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="Roles" subtitle="Browse and Manage your roles!" :badge="this.total" />
    <v-row class="mt-0 mb-1 justify-space-between">
      <v-col>
        <v-btn color="info" @click="add"><i class="fal fa-plus mr-2"></i>Add Role</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="8">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <export-excel />
          <export-pdf />
          <columns-visibility-control
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      @click:row="rowClicked"
      class="elevation-2 roles-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template #progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>
      <template #[`item.name`]="{ item }">
        <v-chip
          style="font-size: 11px; font-weight: 600"
          :key="item.name"
          label
          x-small
          color="deep-purple"
          text-color="white"
          :ripple="false"
        >
          {{ item.name }}
        </v-chip>
      </template>
      <template #[`item.isDefault`]="{ item }">
        <v-chip
          v-if="item.isDefault"
          small
          x-small
          style="font-size: 11px; font-weight: 600"
          color="amber"
          text-color="gray darken-2"
        >
          <i class="far fa-check mr-2" style="line-height: 0"></i> Default
        </v-chip>
        <span v-else><i class="fal fa-times pl-2 text--disabled" style="line-height: 0"></i></span>
      </template>
      <template #[`item.isSystem`]="{ item }">
        <v-chip
          v-if="item.isSystem"
          small
          x-small
          style="font-size: 11px; font-weight: 600"
          color="red"
          text-color="white"
        >
          <i class="far fa-check mr-2" style="line-height: 0"></i> System
        </v-chip>
        <span v-else><i class="fal fa-times pl-2 text--disabled" style="line-height: 0"></i></span>
      </template>
      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu dense offset-x right v-if="!item.isSystem || currentUser.isSuperUser">
          <template #activator="{ attrs, on }">
            <v-btn small icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="update(item.id)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Update</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="openCloneModal(item)" v-if="$has(perms.Roles.Create)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-clone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-1"></v-divider>
            <v-list-item @click="del(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template #loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <edit-role
      @save="onRoleSaved"
      @delete="onRoleDeleted"
      @close="onRoleClosed"
      @clone="onRoleCloned"
      ref="editRole"
    />
    <clone-role ref="cloneRoleModal" @clone="onRoleCloned"></clone-role>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import EditRole from "../components/EditRole.vue";
import roleHeader from "../config/tables/role.header";
import ApiService from "../services/roles-service";
import ExportExcel from "../components/ExportExcel.vue";
import ExportPdf from "../components/ExportPdf.vue";
import CloneRole from "../components/CloneRole.vue";

export default {
  data() {
    return {
      perms,
      storageKey: "Roles",
      paramId: null,
      entities: [],
      selected: {},
      total: 0,
      search: "",
      valid: false,
      rolesTimerId: null,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["name"],
        sortDesc: [false],
        createdByIds: [],
        updatedByIds: [],
      },
      loadingStates: {
        table: false,
      },

      headers: roleHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
    currentUser() {
      return this.$store.getters.user;
    },
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    //this.$refs.mainSearch.focus();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    openCloneModal(role) {
      this.$refs.cloneRoleModal.open(role);
    },
    getData() {
      this.$backToTop(0, document.querySelector(".roles-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          if (resp == null) return;
          this.entities = resp.data.items || resp.data;
          this.total = resp.data.total;
          this.loadingStates.table = false;
          if (this.$route.params.id) {
            this.view(this.$route.params.id);
          }
        })
        .catch((err) => {
          this.$handleError(err);
          this.loadingStates.table = false;
        });
    },
    add() {
      this.selected = {};
      this.$refs.editRole.open(null);
    },
    update(id) {
      this.selected = { id: id };
      setTimeout(() => {
        const path = `/roles/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editRole.open(id, true);
      });
    },
    view(id) {
      setTimeout(() => {
        const path = `/roles/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editRole.open(id);
      });
    },
    rowClicked(row) {
      this.view(row.id);
    },
    del(role) {
      this.$dialog.warning({
        text: `Are you sure you want to delete this role: <b>${role.name}</b>?`,
        title: `Delete Role?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              return ApiService.delete(role.id)
                .then((resp) => {
                  this.onRoleDeleted(role.id);
                  this.$dialog.notify.success("Role deleted successfully", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during deleting the item";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
    onRoleDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    onRoleClosed() {
      const path = `/roles`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onRoleSaved(roleData, isNewRole) {
      this.$store.commit("updateRole", roleData);
      if (isNewRole) {
        this.addToArr(this.entities, roleData);
      } else {
        this.updateArr(this.entities, roleData);
      }
    },
    onRoleCloned(roleData) {
      this.$log("Roles => onRoleCloned", roleData);
      this.addToArr(this.entities, roleData);
      this.view(roleData.id);
    },
    getDataDebounced() {
      if (this.rolesTimerId == null) {
        this.rolesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.rolesTimerId);

      // delay new call 400ms
      this.rolesTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { EditRole, ExportExcel, ExportPdf, CloneRole },
};
</script>

<style lang="scss">
.roles-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
